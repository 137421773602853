/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "./styles/app.scss";
import "./utils/autocomplete";
import "./utils/cookieconsent";
import 'bootstrap-icons/font/bootstrap-icons.css';

// assets/js/ckeditor.js
// You can add custom CKEditor configuration here


const $ = require("jquery");
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require("bootstrap");


$(document).ready(function () {
  console.log($("#bedetungDetail .collapse"));
  const accordion = document.querySelector("#bedetungDetail");

  if (accordion) {
    accordion.addEventListener("hidden.bs.collapse", function (e) {
      console.log("Hidden", e.target);
      localStorage.setItem(e.target.id, "false");
    });

    accordion.addEventListener("shown.bs.collapse", function (e) {
      localStorage.setItem(e.target.id, "true");
    });

    // Zustand wiederherstellen, wenn die Seite geladen wird
    $("#bedetungDetail .collapse").each(function () {
      console.log($(this).attr("id"), 2);
      const isCollapsed = localStorage.getItem($(this).attr("id"));
      if (isCollapsed === "true") {
        $(this).addClass("show");
      } else if (isCollapsed === "false") {
        $(this).removeClass("show");
      }
    });

    $("#bedetungDetail .accordion-button").each(function () {
      const isCollapsed = localStorage.getItem(
        $(this).attr("data-bs-target").replace("#", "")
      );
      console.log($(this).attr("data-bs-target"), isCollapsed);
      if (isCollapsed === "true") {
        $(this).removeClass("collapsed");
      } else if (isCollapsed === "false") {
        $(this).addClass("collapsed");
      }
    });
  }
});
