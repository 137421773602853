const baseUrl = '/';
document.addEventListener('DOMContentLoaded', function() {
    let input = document.getElementById('autocomplete-input');
    let resultsDiv = document.getElementById('autocomplete-results');
    let currentFocus = -1;
    let timer; 

    document.addEventListener('click', function (e) {
        if (!input.contains(e.target) && !resultsDiv.contains(e.target)) {
            resultsDiv.style.display = 'none';
            input.classList.remove('active'); 
        }
        else{
            resultsDiv.style.display = 'block';
            input.classList.add('active'); 
            console.log(input)
        }
    });
    input.addEventListener('keyup', function(e) {
        if (e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13) {
            clearTimeout(timer);
    
            timer = setTimeout(function() {
                let value = input.value.toLowerCase();
    
                if (value.length > 1) {
                    fetchData(value).then(filteredData => {
                        renderResults(filteredData);
                    });
                } else {
                    resultsDiv.innerHTML = '';
                }
            }, 500);  
        }
    });

    async function fetchData(query) {
        const response = await fetch(baseUrl + `api/autocomplete?search=${query}`);
        const data = await response.json();

        return data;
    }
    
    input.addEventListener('keydown', function(e) {
        let x = resultsDiv.querySelectorAll('.autocomplete-item');
        if (e.keyCode === 40) { // Down arrow key
            currentFocus++;
            setActive(x);
        } else if (e.keyCode === 38) { // Up arrow key
            currentFocus--;
            setActive(x);
        } else if (e.keyCode === 13) { // Enter key
            e.preventDefault();
            if (currentFocus > -1) {
                if (x) x[currentFocus].click();
            }
        }
    });

    function setActive(x) {
        if (!x) return;
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        x[currentFocus].classList.add('autocomplete-active');
    }

    function removeActive(x) {
        for (let i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
        }
    }

    function renderResults(data) {
        resultsDiv.innerHTML = '';
        data.forEach(item => {
            let div = document.createElement('div');
            div.className = 'autocomplete-item';
            console.log(item)
            if(item.image) {
                let img = document.createElement('img');
                img.src = item.image;
                div.appendChild(img);
            }

            console.log(item);
            let text = item.wort;
            if(item.pe){
                text += ' - ' + item.pe;
            }
            text = '<span class="ms-0">'+text+'</span>';
            if(item.score == 3){
                text += '<span class="ms-2 info">پیدا شده در فرم های مختلف کلمه</span>';
            }
            else if(item.score == 3){
                text += '<span class="ms-2 info">پیدا شده در مترادف یا متضاد</span>';
            }
            div.innerHTML = text;
            div.addEventListener('click', function() {
                window.location.href = baseUrl + item.url;  
                input.value = item.wort;
                resultsDiv.innerHTML = '';
            });
            resultsDiv.appendChild(div);
        });
    }
});
